<template>
  <div class="top-nav-external-routes" data-nosnippet>
    <a
      v-for="(route, i) in $props.routes"
      :href="route.href"
      :title="route.description"
      :key="`TopNavExternalRoute-${i}`"
      :ref="`TopNavExternalRoute-${i}`"
      class="cta-header"
      rel="noreferrer,noopener"
      target="_blank"
    >
      <span>
        <font-awesome-icon :icon="route.icon" />
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "TopNavExternalRoutes",
  props: {
    /*
     * @property - routes
     * Routes for top nav
     */
    routes: {
      type: Array,
      required: true
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls ExternalRoutes fade and translate stepped animation
     * @param - none
     */
    animateEntry() {
      const delay = i => 1250 + 100 * i;

      this.$props.routes.forEach((route, i) => {
        const j = this.$props.routes.length - (i + 1);
        const routeEl = this.$refs[`TopNavExternalRoute-${j}`][0];

        this.m_animateTranslation(routeEl, 300, -100, 0, 0, 0, delay(i), false);
        this.m_animateFade(routeEl, 500, "in", delay(i));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-nav-external-routes {
  flex: auto;
  display: inherit;
  justify-content: flex-end;
}
</style>
