<template>
  <div class="top-nav-internal-desktop-routes" data-nosnippet>
    <a
      v-for="(route, i) in $props.routes"
      :href="$utils.getPageRoute(route.href)"
      :title="$t(route.description)"
      :key="`TopNavInternalDesktopRoute-${i}`"
      :ref="`TopNavInternalDesktopRoute-${i}`"
      :class="['cta-header', { active: $utils.routeIsActive(route.href) }]"
    >
      <span>
        <font-awesome-icon :class="{ texted: $props.showText }" :icon="route.icon" />
        <template v-if="$props.showText">{{ $t(route.description) }}</template>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "TopNavInternalDesktopRoutes",
  props: {
    /*
     * @property - routes
     * Routes for top nav
     */
    routes: {
      type: Array,
      required: true
    },
    /*
     * @property - showText
     * Flag to see if text should be shown
     */
    showText: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls DesktopRoutes fade and translate stepped animation
     * @param - none
     */
    animateEntry() {
      const delay = i => 500 + 100 * i;

      this.$props.routes.forEach((route, i) => {
        const routeEl = this.$refs[`TopNavInternalDesktopRoute-${i}`][0];

        this.m_animateTranslation(routeEl, 300, 100, 0, 0, 0, delay(i), false);
        this.m_animateFade(routeEl, 500, "in", delay(i));
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-nav-internal-desktop-routes {
  flex: 1 1 auto;
  display: inherit;
}
</style>
