<template>
  <div class="top-nav-internal-mobile-routes" data-nosnippet>
    <button
      @click="animateMenu(null)"
      :disabled="hamburgerDisabled"
      class="cta-header hamburger-menu"
      ref="HamburgerMenu"
      type="button"
      name="hamburger-menu"
    >
      <span>
        <font-awesome-icon
          :class="['top-nav-internal-mobile-routes-hamburger-menu-icon', { opened: menuState }]"
          :icon="['fas', 'bars']"
        />
      </span>
    </button>
    <div
      v-show="menuState"
      :class="['top-nav-internal-mobile-routes-container', { scrolled: $props.isScrolled }]"
      ref="TopNavInternalMobileRoutes"
    >
      <div class="top-nav-internal-mobile-routes-container-internal">
        <div class="top-nav-internal-mobile-routes-container-internal-wrapper">
          <a
            v-for="(route, i) in $props.internalRoutes"
            :href="$utils.getPageRoute(route.href)"
            :title="$t(route.description)"
            :key="`TopNavInternalMobileRoute-${i}`"
            :class="[
              'general-anchor top-nav-internal-mobile-routes-container-internal-wrapper-cta',
              { active: $utils.routeIsActive(route.href) }
            ]"
          >
            <span>
              <font-awesome-icon :icon="route.icon" />
              {{ $t(route.description) }}
            </span>
          </a>
        </div>
      </div>
      <div class="top-nav-internal-mobile-routes-container-external flex">
        <top-nav-external-routes :routes="$props.externalRoutes" />
      </div>
    </div>
  </div>
</template>

<script>
const TopNavExternalRoutes = () =>
  import(
    /* webpackChunkName: "TopNavComponent" */ "@/components/Global/TopNav/Routes/TopNavExternalRoutes.vue"
  );

export default {
  name: "TopNavInternalMobileRoutes",
  components: {
    TopNavExternalRoutes
  },
  props: {
    /*
     * @property - internalRoutes
     * Routes for internal top nav
     */
    internalRoutes: {
      type: Array,
      required: true
    },
    /*
     * @property - externalRoutes
     * Routes for external top nav
     */
    externalRoutes: {
      type: Array,
      required: true
    },
    /*
     * @property - isScrolled
     * Flag to see if window is scrolled
     */
    isScrolled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuState: false,
      hamburgerDisabled: false
    };
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls HamburgerMenu fade and translate stepped animation
     * @param - none
     */
    animateEntry() {
      const { HamburgerMenu } = this.$refs;

      this.m_animateTranslation(HamburgerMenu, 300, -100, 0, 0, 0, 500, false);
      this.m_animateFade(HamburgerMenu, 500, "in", 500);
    },
    /*
     * @function - animateMenu
     * Calls fade in ot out for menu, and sets if hamburger cta should be enabled or disabled
     * @param - none
     */
    animateMenu(state = null) {
      const newState = state !== null ? state : !this.menuState;
      this.hamburgerDisabled = true;

      this.m_animateFade(
        this.$refs.TopNavInternalMobileRoutes,
        500,
        this.menuState ? "out" : "in"
      ).then(() => {
        this.hamburgerDisabled = false;
        if (!newState) {
          this.menuState = newState;
        }
      });

      if (newState) {
        this.menuState = newState;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top-nav-internal-mobile-routes {
  flex: auto;
  display: inherit;
  justify-content: flex-end;

  &-hamburger-menu-icon {
    transition: transform 0.5s ease;

    &.opened {
      transform: rotate(90deg);
    }
  }

  &-container {
    background-color: $white-gray;
    border-top: 0.0625rem solid $main-green;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    overflow: auto;
    top: 4.25rem;
    transition: background-color 1.5s ease, top 0.5s ease;

    &.scrolled {
      background-color: rgba($white-gray, 0.85);
      top: 2.25rem;
    }

    &-internal {
      background-color: transparent;
      display: table;
      height: calc(100% - 5.75rem);
      margin-left: auto;
      margin-right: auto;

      &-wrapper {
        display: table-cell;
        vertical-align: middle;

        &-cta {
          display: block;
          margin: 1rem 0;
        }
      }
    }

    &-external {
      border-top: 0.0625rem solid $main-green;
      height: 4.25rem;
      margin-top: 1.5rem;
    }
  }
}
</style>
