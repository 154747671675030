<template>
  <header ref="TopNav" :class="['top-nav flex transparent', { scrolled: isScrolled }]">
    <div class="top-nav-imagotype">
      <a
        :href="$utils.getPageRoute()"
        :title="$store.state.Global.env.APPLICATION_NAME"
        class="top-nav-imagotype-cta"
      ></a>
      <object
        v-if="$store.state.Global.online"
        :data="imagotype"
        height="100%"
        width="auto"
        ref="Imagotype"
        type="image/svg+xml"
      ></object>
      <img
        v-else
        ref="ImagotypePng"
        src="/img/imagotype/onca-vega-imagotipo-233x120.png"
        alt="Onca Vega Logo"
        height="100%"
        width="auto"
      />
    </div>
    <top-nav-internal-mobile-routes
      v-if="windowWidth < breakpoints.small"
      :internalRoutes="routes.internal"
      :externalRoutes="routes.external"
      :isScrolled="isScrolled"
    />
    <top-nav-internal-desktop-routes
      v-else
      :routes="routes.internal"
      :showText="windowWidth >= breakpoints.large && !isScrolled"
    />
    <top-nav-external-routes v-if="windowWidth >= breakpoints.small" :routes="routes.external" />
  </header>
</template>

<script>
import imagotype from "@/assets/images/imagotype/onca-vega-imagotipo-233x120.svg";

const TopNavInternalMobileRoutes = () =>
  import(
    /* webpackChunkName: "TopNavComponent" */ "@/components/Global/TopNav/Routes/TopNavInternalMobileRoutes.vue"
  );
const TopNavInternalDesktopRoutes = () =>
  import(
    /* webpackChunkName: "TopNavComponent" */ "@/components/Global/TopNav/Routes/TopNavInternalDesktopRoutes.vue"
  );
const TopNavExternalRoutes = () =>
  import(
    /* webpackChunkName: "TopNavComponent" */ "@/components/Global/TopNav/Routes/TopNavExternalRoutes.vue"
  );

export default {
  name: "TopNav",
  components: {
    TopNavInternalMobileRoutes,
    TopNavInternalDesktopRoutes,
    TopNavExternalRoutes
  },
  data() {
    return {
      imagotype
    };
  },
  computed: {
    /*
     * @computed - windowWidth
     * Gets window width from vuex state
     */
    windowWidth() {
      return this.$store.state.Global.window.width;
    },
    /*
     * @computed - breakpoints
     * Gets breakpoints from utils plugin
     */
    breakpoints() {
      return this.$utils.styles.breakpoints;
    },
    /*
     * @computed - isScrolled
     * True is window scrollY is bigger or equal to 10
     */
    isScrolled() {
      return this.$store.state.Global.window.scroll >= 10;
    },
    /*
     * @computed - routes
     * Gets routes from utils plugin
     */
    routes() {
      return this.$utils.routes;
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls topnav fade and imagotype animation
     * @param - none
     */
    animateEntry() {
      this.m_animateFade(this.$refs.TopNav, 1000, "in");
      this.$store.state.Global.online ? this.imagotypeListener() : this.animateImagotypePng();
    },
    /*
     * @function - animateImagotypePng
     * Calls imagotype png animation
     * @param - none
     */
    animateImagotypePng() {
      const { ImagotypePng } = this.$refs;

      this.m_animateFade(ImagotypePng, 575, "in")
        .then(() => this.m_animateFade(ImagotypePng, 575))
        .then(() => this.m_animateFade(ImagotypePng, 575, "in"))
        .then(() => this.m_animateFade(ImagotypePng, 575))
        .then(() => this.m_animateFade(ImagotypePng, 575, "in"));
    },
    /*
     * @function - imagotypeListener
     * Adds or removes event listener for imagotype svg image animation
     * @param - state (String): if true, listener will be added, otherwise will be removed
     */
    imagotypeListener(state = true) {
      const { Imagotype } = this.$refs;

      if (Imagotype) {
        Imagotype[state ? "addEventListener" : "removeEventListener"](
          "load",
          this.animateImagotype
        );
      }
    },
    /*
     * @function - animateImagotype
     * Calls imagotype animation
     * @param - none
     */
    animateImagotype() {
      this.m_animateImagotype(this.$refs.Imagotype.contentDocument);
    }
  },
  beforeDestroy() {
    this.imagotypeListener(false);
  }
};
</script>

<style lang="scss" scoped>
.top-nav {
  @include boxShadow(0.65, true, false);
  background-color: $white-gray;
  height: 4.25rem;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 800;
  transition: background-color 1.5s ease, box-shadow 1.5s ease, height 0.5s ease;

  @include breakpoint($small) {
    height: 5rem;
  }

  @include breakpoint($large) {
    height: 5.75rem;
  }

  &.scrolled {
    @include boxShadow(0.35);
    background-color: rgba($white-gray, 0.35);
    height: 2.25rem;

    @include breakpoint($small) {
      height: 3rem;
    }

    @include breakpoint($large) {
      height: 3.75rem;
    }
  }

  &-imagotype {
    flex: 0 0 auto;
    height: auto;
    padding: 0.2rem;
    position: relative;
    width: 8.25rem;

    @include breakpoint($small) {
      padding: 0.25rem;
      width: 10.025rem;
    }

    @include breakpoint($large) {
      width: 11.875rem;
    }

    &-cta {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
